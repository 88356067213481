import * as d3 from "d3";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { comma, debounce } from "utils/lib";
import BackTestXRange from "./BackTestXRange";
import { Frame } from "interfaces/common.interface";
import { IMaterialsPack, IMaterialsPriceHistories } from "interfaces/material.interface";
import { BarItem, Item, Line, MACD, NumberPos, PointItem, Pos } from "interfaces/backtest.interface";
import { group, horizontal } from "utils/d3.utils";

type D3_SVGElement = d3.Selection<SVGSVGElement, unknown, null, any>;
type Group = d3.Selection<SVGGElement, unknown, null, any>;

interface Props {
  items: BarItem[];
  strengthType: string;
  strength: Item[];
  point: PointItem[];
  ma: { [key: string]: Item[] };
  macd: MACD;
  lim: { min: number; max: number };
  movingAverageIndex: number[];
  movingAverageColor: string[];
  materials: IMaterialsPack;
  dynamicLines: Line[];
  setDynamicLines: React.Dispatch<React.SetStateAction<Line[]>>;
  options?: {};
}

export const MARGIN = {
  top: 30,
  left: 40,
  right: 40,
  bottom: 30,
};

const MACD_Y_RATIO = 0.13;
const STRENGTH_Y_RATIO = 0.13;
const MAIN_Y_RATIO = 1 - MACD_Y_RATIO - STRENGTH_Y_RATIO;

const STROKE = {
  STOCK: { COLOR: "#444444", THICK: 1.2 },
  MA: { THICK: 0.6 },
};

export default function BackTestGraph({
  items,
  lim,
  ma,
  materials,
  strengthType,
  strength,
  macd,
  point,
  dynamicLines,
  setDynamicLines,
  movingAverageIndex,
  movingAverageColor,
}: Props) {
  const ref = useRef<HTMLDivElement | null>(null);
  const svg = useRef<D3_SVGElement | null>(null);
  const crosshair = useRef<Group | null>(null);
  const pos = useRef<Pos | null>(null);

  const [frame, setFrame] = useState({ width: 0, height: 0 });

  // 동적 y 축 범위
  const [selectionLim, setSelectionLim] = useState<{
    min: number | null;
    max: number | null;
  }>({ min: null, max: null });

  // 동적 x 축 범위
  const [selection, setSelection] = useState<{
    start: Date | null;
    end: Date | null;
  }>({ start: null, end: null });

  const ylim = useMemo(() => {
    if (items.length === 0) return [0, 0];
    const y0 = selectionLim.min ? selectionLim.min : lim.min;
    const y1 = selectionLim.max ? selectionLim.max : lim.max;
    return [y0, y1];
  }, [items, selectionLim, lim]);

  const xlim = useMemo(() => {
    if (items.length === 0) return [new Date(), new Date()];

    const x0 = selection.start ? selection.start : items[0].x;
    let x1 = selection.end ? selection.end : items[items.length - 1].x;

    // 한달정도 더 앞으로 보냄
    x1 = dayjs(x1).add(14, "day").toDate();

    return [x0, x1];
  }, [items, selection]);

  const macdlim = useMemo(() => {
    const flat = [...macd.histogram, ...macd.signal];
    const min = d3.min(flat, (d) => d.y) || 0;
    const max = d3.max(flat, (d) => d.y) || 0;

    const abs = Math.abs(min) > Math.abs(max) ? Math.abs(min) : Math.abs(max);

    const values = [abs, 0, abs * -1];

    return [abs * -1, abs, values] as [number, number, number[]];
  }, [macd]);

  const xScale = useMemo(() => {
    const width = frame.width - MARGIN.left - MARGIN.right;
    return d3.scaleTime().domain(xlim).range([0, width]);
  }, [xlim, frame]);

  const yScale = useMemo(() => {
    const height = frame.height - MARGIN.top - MARGIN.bottom;
    return d3.scaleTime().domain(ylim).range([height, 0]);
  }, [ylim, frame]);

  const yStockScale = useMemo(() => {
    const height = (frame.height - MARGIN.top - MARGIN.bottom) * MAIN_Y_RATIO;
    const gap = (lim.max - lim.min) * 0.05;

    return d3
      .scaleLinear()
      .domain([ylim[0] - gap, ylim[1]])
      .range([height, 0]);
  }, [ylim, frame, lim]);

  // strength min max
  const strLim = useMemo(() => {
    return strengthType === "RSI" ? [30, 70] : [20, 80];
  }, [strengthType]);

  const bisect = useMemo(() => d3.bisector<Item, Date>((d) => d.x).right, []);

  /**
   * crosshair 및 위치 데이터 업데이트
   */
  const updateCursor = useCallback(
    (date: Date, price: number) => {
      if (!crosshair.current) return;

      const i = bisect(items, date); // 현재 위치의 가격
      const cursorPrice = items[i] ? comma(items[i].y) : "";
      const pointerPrice = price > 0 ? comma(price.toFixed(2)) : 0;
      const cursorDate = dayjs(date).format("YY/MM/DD");
      const text = `${cursorDate} C ${cursorPrice} P ${pointerPrice}`;

      crosshair.current.select("#detail").text(text);

      crosshair.current
        .select("#crosshair-x")
        .datum(xlim)
        .attr(
          "d",
          d3
            .line<Date>()
            .x((d) => xScale(d))
            .y(yStockScale(price))
        );

      crosshair.current
        .select("#crosshair-y")
        .datum(ylim)
        .attr(
          "d",
          d3
            .line<number>()
            .x(xScale(date))
            .y((d) => yScale(d))
        );

      crosshair.current.lower();
    },
    [xlim, ylim, xScale, yScale, yStockScale, items, bisect]
  );

  /**
   *
   */
  const mousemove = useCallback(
    function (ev: any) {
      if (!crosshair.current) return;

      const pointer = d3.pointer(ev, this);
      const [x, y] = pointer;

      if (svg.current && pos.current) {
        const g = svg.current.select(".dynamic-line-area");

        g.select(".temp-line")
          .datum([
            { x: xScale(pos.current.x), y: yStockScale(pos.current.y) },
            { x, y },
          ])
          .attr("fill", "none")
          .attr("stroke", "#ffaa00")
          .attr("stroke-width", 1.5)
          .attr(
            "d",
            d3
              .line<NumberPos>()
              .x((d) => d.x)
              .y((d) => d.y)
          );
      }

      updateCursor(xScale.invert(x), yStockScale.invert(y));
    },
    [xScale, yStockScale, updateCursor]
  );

  /**
   *
   */
  const mouseup = useCallback(
    (ev: any) => {
      if (!pos.current) return;
      const point = d3.pointer(ev, this);

      const p0 = { ...pos.current };

      setDynamicLines((prevState) => [
        ...prevState,
        {
          p0,
          p1: { x: xScale.invert(point[0]), y: yStockScale.invert(point[1]) },
        },
      ]);

      pos.current = null;

      if (svg.current && svg.current.select(".dynamic-line-area")) {
        svg.current.select(".dynamic-line-area").remove();
      }
    },
    [setDynamicLines, xScale, yStockScale]
  );

  /**
   *
   */
  const mousedown = useCallback(
    (ev: any) => {
      const point = d3.pointer(ev, this);

      if (svg.current) {
        svg.current
          .select(".dynamic-line-area")
          .append("path")
          .classed("temp-line", true)
          .on("mouseup", (ev) => {
            ev.stopPropagation();
            mouseup(ev);
          });
      }

      pos.current = {
        x: xScale.invert(point[0]),
        y: yStockScale.invert(point[1]),
      };
    },
    [mouseup, xScale, yStockScale]
  );

  /** */
  const click = useCallback(
    function (ev: any) {
      const point = d3.pointer(ev, this);

      if (!pos.current) {
        // 임의 path 추가 mousemove 시 그려진다.
        if (svg.current) {
          svg.current
            .select(".dynamic-line-area")
            .append("path")
            .classed("temp-line", true)
            .on("click", (ev) => {
              ev.stopPropagation();
              click(ev);
            });
        }

        pos.current = {
          x: xScale.invert(point[0]),
          y: yStockScale.invert(point[1]),
        };
      } else {
        const p0 = { ...pos.current };

        setDynamicLines((prevState) => [
          ...prevState,
          {
            p0,
            p1: { x: xScale.invert(point[0]), y: yStockScale.invert(point[1]) },
          },
        ]);

        pos.current = null;

        if (svg.current && svg.current.select(".dynamic-line-area")) {
          svg.current.select(".dynamic-line-area").remove();
        }
      }
    },
    [xScale, yStockScale, setDynamicLines]
  );

  /**
   *
   */
  const drawBottomAxis = useCallback(
    (svg: D3_SVGElement, frame: Frame) => {
      const g = svg
        .append("g")
        .classed("x-axis", true)
        .attr("transform", `translate(${MARGIN.left}, ${frame.height - MARGIN.bottom})`);

      const xAxis = d3.axisBottom(xScale).tickValues(xScale.ticks());
      g.style("font-size", 8).call(xAxis);
    },
    [xScale]
  );

  /**
   *
   */
  const drawStockAxis = useCallback(
    (svg: D3_SVGElement, ylim: number[], last: Item) => {
      const g = svg
        .append("g")
        .classed("y-stock-axis", true)
        .attr("transform", `translate(${MARGIN.left}, ${MARGIN.top})`);

      g.style("font-size", 8).call(
        d3
          .axisLeft(yStockScale)
          .tickSize(4)
          .tickValues([...ylim, last.y])
      );
    },
    [yStockScale]
  );

  /**
   *
   */
  const drawStrengthAxis = useCallback(
    (svg: D3_SVGElement, frame: Frame) => {
      const stockHeight = (frame.height - MARGIN.top - MARGIN.bottom) * (MAIN_Y_RATIO + MACD_Y_RATIO);

      const g = svg.append("g").attr("transform", `translate(${MARGIN.left}, ${stockHeight + MARGIN.top})`);

      const height = (frame.height - MARGIN.top - MARGIN.bottom) * STRENGTH_Y_RATIO;

      const yScale = d3.scaleLinear().domain([0, 100]).range([height, 0]);

      g.style("font-size", 8).call(
        d3
          .axisLeft(yScale)
          .tickSize(4)
          .tickValues([...strLim, 50])
      );

      return yScale;
    },
    [strLim]
  );

  const drawMACDAxis = useCallback(
    (svg: D3_SVGElement, frame: Frame) => {
      const stockHeight = (frame.height - MARGIN.top - MARGIN.bottom) * MAIN_Y_RATIO;

      const g = svg.append("g").attr("transform", `translate(${MARGIN.left}, ${stockHeight + MARGIN.top})`);

      const height = (frame.height - MARGIN.top - MARGIN.bottom) * MACD_Y_RATIO;
      const [min, max, values] = macdlim;

      const yScale = d3.scaleLinear().domain([min, max]).range([height, 0]);

      g.style("font-size", 8).call(d3.axisLeft(yScale).tickSize(4).tickValues(values));

      return yScale;
    },
    [macdlim]
  );

  /**
   * price 기록 그리기
   */
  // const drawLine = useCallback(
  //   (
  //     svg: D3_SVGElement,
  //     frame: Frame,
  //     data: Item[],
  //     xScale: d3.ScaleTime<number, number, never>,
  //     yScale: d3.ScaleLinear<number, number, never>
  //   ) => {
  //     // mousemove 영역 색성
  //     const width = frame.width - MARGIN.left - MARGIN.right;

  //     const wrapper = svg.append("g").attr("transform", `translate(${MARGIN.left}, ${MARGIN.top})`);

  //     const g = wrapper.append("g").classed("close-line", true).attr("clip-path", "url(#stock-clip)");

  //     g.append("path")
  //       .datum(data)
  //       .attr("fill", "none")
  //       .attr("stroke", STROKE.STOCK.COLOR)
  //       .attr("stroke-width", STROKE.STOCK.THICK)
  //       .attr(
  //         "d",
  //         d3
  //           .line<Item>()
  //           .x((d) => xScale(d.x))
  //           .y((d) => yScale(d.y))
  //       );

  //     // Event Point 그리기 (RSI, Golden Cross, Dead Corss 등...)
  //     const pointGroup = wrapper.append("g").attr("clip-path", "url(#stock-clip)");

  //     for (let i = 0; i < point.length; i++) {
  //       const { x, y, event } = point[i];

  //       if (event === "NEW_52_WEEK_HIGH") {
  //         pointGroup
  //           .append("path")
  //           .datum([0, width])
  //           .attr("stroke", "#ff9d39")
  //           .attr("stroke-width", 0.7)
  //           .attr(
  //             "d",
  //             d3
  //               .line<number>()
  //               .x((d) => d)
  //               .y(yScale(y))
  //           );

  //         pointGroup
  //           .append("text")
  //           .attr("x", xScale(x))
  //           .attr("y", yScale(y) - 4)
  //           .attr("text-anchor", "middle")
  //           .attr("fill", "#ff9d39")
  //           .style("font-size", 8)
  //           .text("new 52 week high");
  //       } else {
  //         const triangle = d3.symbol().type(d3.symbolTriangle2).size(20);
  //         const color = event === "STR_UP" ? "red" : "blue";
  //         const rotate = event === "STR_UP" ? 0 : 180;
  //         const margin = event === "STR_UP" ? 10 : -10;
  //         const translateX = xScale(x) - 2.5;
  //         const translateY = yScale(y) + margin;

  //         pointGroup
  //           .append("path")
  //           .attr("d", triangle)
  //           .attr("fill", color)
  //           .attr("transform", `translate(${translateX}, ${translateY}) rotate(${rotate})`);
  //       }
  //     }

  //     // clip 추가
  //     wrapper
  //       .append("defs")
  //       .append("clipPath")
  //       .attr("id", "stock-clip")
  //       .append("rect")
  //       .attr("x", 0)
  //       .attr("y", 0)
  //       .attr("width", width)
  //       .attr("height", frame.height - MARGIN.top - MARGIN.bottom);
  //   },
  //   [point]
  // );

  const drawBar = useCallback(
    (
      svg: D3_SVGElement,
      frame: Frame,
      data: BarItem[],
      xScale: d3.ScaleTime<number, number, never>,
      yScale: d3.ScaleLinear<number, number, never>
    ) => {
      const g = group(svg, {
        transform: {
          x: MARGIN.left,
          y: MARGIN.top,
        },
      });

      const format = data.map((d) => {
        const isUp = d.o < d.y;
        const start = isUp ? d.y : d.o;
        const end = isUp ? d.o : d.y;

        return {
          x: d.x,
          high: d.h,
          start,
          end,
          low: d.l,
          height: end - start || 1, // height, 0 순서라서 거꾸로 계산해야함
          color: isUp ? "#ee373a" : "#097df2",
          isUp,
        };
      });

      const rectGroup = g.selectAll("rect").data(format).enter();

      const width = frame.width / data.length;

      rectGroup
        .append("rect")
        // .classed(CLASS_NAMES.STOCK_BAR_HEAD, true)
        .attr("x", (d) => (xScale(d.x) || 0) + width / 2 - 0.5)
        .attr("y", (d) => yScale(d.high))
        .attr("height", (d) => yScale(d.start) - yScale(d.high))
        .attr("width", 1)
        .attr("fill", (d) => d.color);

      // main
      rectGroup
        .append("rect")
        // .classed(CLASS_NAMES.STOCK_BAR_BODY, true)
        .attr("x", (d) => xScale(d.x) || 0)
        .attr("y", (d) => yScale(d.start))
        .attr("height", (d) => yScale(d.end) - yScale(d.start) || 1)
        .attr("width", width)
        .attr("fill", (d) => d.color);

      rectGroup
        .append("rect")
        // .classed(CLASS_NAMES.STOCK_BAR_TAIL, true)
        .attr("x", (d) => (xScale(d.x) || 0) + width / 2 - 0.5)
        .attr("y", (d) => yScale(d.end))
        .attr("height", (d) => yScale(d.low) - yScale(d.end))
        .attr("width", 1)
        .attr("fill", (d) => d.color);
    },
    []
  );

  /**
   * 이평선
   */
  const drawMovingAverage = useCallback(
    (
      svg: D3_SVGElement,
      frame: Frame,
      data: {
        [key: string]: Item[];
      },
      xScale: d3.ScaleTime<number, number, never>,
      yScale: d3.ScaleLinear<number, number, never>
    ) => {
      const g = svg.append("g").classed("ma-line", true).attr("transform", `translate(${MARGIN.left}, ${MARGIN.top})`);

      for (let j = 0; j < movingAverageIndex.length; j++) {
        const key = `MA_${movingAverageIndex[j]}`;
        const ma = data[key];

        if (!ma) continue;

        const color = movingAverageColor[j];

        g.append("path")
          .datum(ma)
          .attr("fill", "none")
          .attr("stroke", color || "black")
          .attr("stroke-width", STROKE.MA.THICK)
          .attr(
            "d",
            d3
              .line<Item>()
              .x((d) => xScale(d.x))
              .y((d) => yScale(d.y))
          );
      }
    },
    [movingAverageIndex, movingAverageColor]
  );

  /**
   * 커스텀한 line 그리기
   */
  const drawDynamicLines = useCallback(
    (svg: D3_SVGElement, lines: Line[]) => {
      const g = svg.select(".dynamic-line-area");

      lines.forEach(function (line, i) {
        const { p0, p1 } = line;

        g.append("path")
          .datum([p0, p1])
          .attr("data-index", i)
          .attr("fill", "none")
          .attr("stroke", "black")
          .attr("stroke-width", 0.7)
          .attr(
            "d",
            d3
              .line<Pos>()
              .x((d) => xScale(d.x))
              .y((d) => yStockScale(d.y))
          )
          .on("mouseover", function () {
            d3.select(this).attr("stroke-width", 1.4);
          })
          .on("mouseleave", function () {
            d3.select(this).attr("stroke-width", 0.7);
          })
          .on("contextmenu", function (ev) {
            ev.preventDefault();

            if (ev.target.dataset && ev.target.dataset.index) {
              const index = parseInt(ev.target.dataset.index);

              setDynamicLines((prevState) => prevState.filter((_, i) => i !== index));
            }
          });
      });
    },
    [xScale, yStockScale, setDynamicLines]
  );

  /**
   * 심리적 지표 (RSI,MFI)
   */
  const drawStrength = useCallback(
    (
      svg: D3_SVGElement,
      frame: Frame,
      strength: Item[],
      xScale: d3.ScaleTime<number, number, never>,
      yScale: d3.ScaleLinear<number, number, never>
    ) => {
      const stockHeight = (frame.height - MARGIN.top - MARGIN.bottom) * (MAIN_Y_RATIO + MACD_Y_RATIO);

      const g = svg
        .append("g")
        .attr("clip-path", "url(#stock-clip)")
        .attr("transform", `translate(${MARGIN.left}, ${stockHeight + MARGIN.top})`);

      const [min, max] = strLim;
      const xr = xlim.map((d) => xScale(d));

      horizontal(g, xr, yScale(100), { color: "black" });
      horizontal(g, xr, yScale(max), { color: "#ff6969" });
      horizontal(g, xr, yScale(min), { color: "#5d8eff" });
      horizontal(g, xr, yScale(50), { color: "rgba(150, 150, 150, 0.3)" });

      const signal = strength.filter((d) => !!d.signal);

      g.append("path")
        .classed("rsi-signal-path", true)
        .datum(signal)
        .attr("fill", "none")
        .attr("stroke", "#ff7a2d")
        .attr("stroke-width", 0.7)
        .attr(
          "d",
          d3
            .line<Item>()
            .x((d) => xScale(d.x))
            .y((d) => yScale(d.signal || 0))
        );

      g.append("path")
        .classed("rsi-path", true)
        .datum(strength)
        .attr("fill", "none")
        .attr("stroke", "#606060")
        .attr("stroke-width", 0.7)
        .attr(
          "d",
          d3
            .line<Item>()
            .x((d) => xScale(d.x))
            .y((d) => yScale(d.y))
        );
    },
    [xlim, strLim]
  );

  /**
   * MACD 지표 추가
   */
  const drawMACD = useCallback(
    (
      svg: D3_SVGElement,
      frame: Frame,
      macd: MACD,
      xScale: d3.ScaleTime<number, number, never>,
      yScale: d3.ScaleLinear<number, number, never>
    ) => {
      const stockHeight = (frame.height - MARGIN.top - MARGIN.bottom) * MAIN_Y_RATIO;

      const g = svg
        .append("g")
        .attr("clip-path", "url(#stock-clip)")
        .attr("transform", `translate(${MARGIN.left}, ${stockHeight + MARGIN.top})`);

      function line(y: number, options: { color?: string; strokeWidth?: number } = {}) {
        g.append("path")
          .datum(xlim)
          .attr("fill", "none")
          .attr("stroke", options.color || "#aaa")
          .attr("stroke-width", options.strokeWidth || 0.7)
          .attr(
            "d",
            d3
              .line<Date>()
              .x((d) => xScale(d))
              .y(yScale(y))
          );
      }

      line(macdlim[1], { color: "black", strokeWidth: 1 });
      line(0);

      g.append("path")
        .datum(macd.value)
        .attr("fill", "none")
        .attr("stroke", "#a9a9a9")
        .attr("stroke-width", 0.7)
        .attr(
          "d",
          d3
            .line<Item>()
            .x((d) => xScale(d.x))
            .y((d) => yScale(d.y))
        );

      g.append("path")
        .datum(macd.signal)
        .attr("fill", "none")
        .attr("stroke", "#ffaa00")
        .attr("stroke-width", 1)
        .attr(
          "d",
          d3
            .line<Item>()
            .x((d) => xScale(d.x))
            .y((d) => yScale(d.y))
        );
    },
    [xlim, macdlim]
  );

  /**
   * 상세 내용 추가 및 마우스 이벤트
   */
  const drawDetail = useCallback(
    (svg: D3_SVGElement, frame: Frame) => {
      crosshair.current = svg
        .append("g")
        .attr("id", "crosshair")
        .attr("transform", `translate(${MARGIN.left}, ${MARGIN.top})`);

      const width = frame.width - MARGIN.left - MARGIN.right;

      // 날짜 , 가격
      crosshair.current
        .append("text")
        .attr("id", "detail")
        .attr("x", width)
        .attr("y", -10)
        .attr("fill", "black")
        .attr("text-anchor", "end")
        .style("font-size", 12)
        .text("");

      // 가로줄
      crosshair.current
        .append("path")
        .attr("id", "crosshair-x")
        .datum(xlim)
        .attr("fill", "none")
        .attr("stroke", "black")
        .attr("stroke-width", 0.5)
        .attr("stroke-dasharray", [3, 3])
        .attr("d", "");

      // 세로줄
      crosshair.current
        .append("path")
        .attr("id", "crosshair-y")
        .datum([lim.min, lim.max])
        .attr("fill", "none")
        .attr("stroke", "black")
        .attr("stroke-width", 0.5)
        .attr("stroke-dasharray", [3, 3])
        .attr("d", "");
    },
    [xlim, lim]
  );

  /**
   *
   */
  const drawMaterials = useCallback(
    (svg: D3_SVGElement, frame: Frame, materials: IMaterialsPack) => {
      const height = (frame.height - MARGIN.top - MARGIN.bottom) * MAIN_Y_RATIO;

      const g = svg
        .append("g")
        .classed("materials", true)
        .attr("clip-path", "url(#stock-clip)")
        .attr("transform", `translate(${MARGIN.left}, ${MARGIN.top})`);

      for (let key in materials) {
        const data = materials[key as keyof typeof materials];

        if (data) {
          const min = d3.min(data, ({ value }) => value) || 0;
          const max = d3.max(data, ({ value }) => value) || 0;

          const axis = svg.append("g").attr("transform", `translate(${frame.width - MARGIN.right}, ${MARGIN.top})`);

          const yScale = d3.scaleLinear().domain([min, max]).range([height, 0]);
          axis.style("font-size", 8).call(d3.axisRight(yScale).tickSizeOuter(0));

          g.append("path")
            .datum(data)
            .attr("fill", "none")
            .attr("stroke", "#555")
            .attr("stroke-width", 0.7)
            .attr(
              "d",
              d3
                .line<IMaterialsPriceHistories>()
                .x((d) => xScale(new Date(d.timestamp)))
                .y((d) => yScale(d.value))
            );
        }
      }
    },
    [xScale]
  );

  /**
   *
   */
  const mouseInteractive = useCallback(
    (svg: D3_SVGElement, frame: Frame) => {
      // mousemove 영역 색상
      const height = frame.height - MARGIN.top - MARGIN.bottom;
      const width = frame.width - MARGIN.left - MARGIN.right;

      const g = svg.append("g").attr("transform", `translate(${MARGIN.left}, ${MARGIN.top})`);

      g.append("rect")
        .attr("width", width)
        .attr("height", height)
        .attr("fill", "white")
        .attr("opacity", 0.00001)
        .on("mousemove", mousemove)
        .on("mouseup", mouseup)
        .on("mousedown", mousedown);
      // .on("click", click);
    },
    [mousemove, click]
  );

  /**
   *
   */
  const render = useCallback(
    (frame: Frame) => {
      if (!ref.current || items.length === 0) return;
      if (svg.current) svg.current.remove();

      const { width, height } = frame;

      const last = items[items.length - 1];

      svg.current = d3
        .select(ref.current)
        .append("svg")
        .attr("viewBox", [0, 0, width, height])
        .attr("width", width)
        .attr("height", height);

      drawBottomAxis(svg.current, frame);
      drawStockAxis(svg.current, ylim, last);

      // const yMACDScale = drawMACDAxis(svg.current, frame);
      const yStrengthScale = drawStrengthAxis(svg.current, frame);

      // drawLine(svg.current, frame, items, xScale, yStockScale);
      drawBar(svg.current, frame, items, xScale, yStockScale);
      drawMovingAverage(svg.current, frame, ma, xScale, yStockScale);
      // drawMACD(svg.current, frame, macd, xScale, yMACDScale);
      drawStrength(svg.current, frame, strength, xScale, yStrengthScale);

      drawDetail(svg.current, frame);
      drawMaterials(svg.current, frame, materials);

      mouseInteractive(svg.current, frame);

      // Custom Line p0 미리 선언
      svg.current
        .append("g")
        .classed("dynamic-line-area", true)
        .attr("clip-path", "url(#stock-clip)")
        .attr("transform", `translate(${MARGIN.left}, ${MARGIN.top})`);

      drawDynamicLines(svg.current, dynamicLines);
    },
    [
      items,
      strength,
      ma,
      // macd,
      dynamicLines,
      materials,
      ylim,
      drawBottomAxis,
      drawStockAxis,
      drawStrengthAxis,
      // drawMACDAxis,
      drawMovingAverage,
      // drawLine,
      // drawMACD,
      drawStrength,
      drawDetail,
      drawMaterials,
      drawDynamicLines,
      mouseInteractive,
      xScale,
      yStockScale,
    ]
  );

  /**
   *
   */
  const getFrame = useCallback(() => {
    if (!ref.current) return { width: 0, height: 0 };
    const width = ref.current.clientWidth;
    const height = ref.current.clientHeight;
    return { width, height };
  }, []);

  /**
   *
   */
  const resize = useCallback(
    debounce(() => {
      const frame = getFrame();
      setFrame(frame);
    }, 500),
    [getFrame]
  );

  /**
   * frame update
   */
  useEffect(() => {
    render(frame);
  }, [render, frame, items]);

  /**
   * init
   */
  useEffect(() => {
    const { height, width } = getFrame();
    window.addEventListener("resize", resize);

    if (frame.width === width && frame.height === height) return;
    setFrame({ width, height });

    return function cleanup() {
      window.removeEventListener("resize", resize);
    };
  }, [resize, frame, getFrame]);

  return (
    <div className="h-[calc(100%-18px)]">
      <div
        className="h-[calc(100%-36px)]"
        ref={ref}
        onContextMenu={(ev: React.MouseEvent<HTMLDivElement>) => {
          ev.preventDefault();
          return false;
        }}
      />
      <BackTestXRange
        width={frame.width}
        items={items}
        lim={lim}
        setSelection={setSelection}
        setSelectionLim={setSelectionLim}
      />
    </div>
  );
}
